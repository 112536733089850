<template>
  <b-card>
    <b-row
      align-h="between"
      align-v="center"
    >
      <b-col
        sm="4"
        class="text-left"
      >
        <h4 class="m-0 mt-1">
          {{ topic && topic.is_schedulable? "Scheduled Communication": "Communication" }}: {{ topicName }}
        </h4>
        <h4 
          v-if="internalTopicName"
          class="m-0 mt-1"
        >
          Internal name: {{ internalTopicName }}
        </h4>
      </b-col>
    </b-row>
    <vue-good-table 
      class="mt-3" 
      mode="remote"
      :is-loading="isLoading" 
      :columns="columns" 
      :rows="rows"
      :rtl="directionIsRTL" 
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" 
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }" 
      style-class="vgt-table striped" 
      @on-page-change="onPageChange" 
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" 
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'recipient.full_name'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap"
        >
          <div class="align-items-start mb-0 mt-0">
            <router-link
              :to="{
                name: 'champion-program-participant',
                params: { id: programId, participantId: props.row.recipient_id },
              }"
              class="table-primary-link"
            >
              {{ props.row.recipient.full_name }}
            </router-link>
          </div>
        </div>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="(value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="(value) => props.pageChanged({ currentPage: value })"
        />
      </template>
    </vue-good-table>
  </b-card>
</template>
  
<script>
import { VBToggle, BCard, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { makeErrorToast } from "@/libs/utils";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import { commsService } from "@/services";
import { messageStatus, messageStatusDisplay, topicTypes } from "@/models";
import { localeDateStringFromIsoDateTime } from "@/libs/utils";

export default {
  components: {
    BCard,
    VueGoodTable,
    TablePagination,
    BCard,
    BRow,
    BCol
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
      topicName: "",
      internalTopicName: "",
      topic: null,
      columns: []
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    programId() {
      return this.$route.params.id;
    },
  },
  watch: {
    topic(newValue, oldValue) {
      if (newValue?.id != oldValue?.id) {
        this.columns = [
        ...(this.topic && this.topic?.type_id !== topicTypes.INVITE? [{
          label: "User",
          field: "recipient.full_name",
          filterOptions: {
            enabled: true,
            placeholder: "Search User",
          },
          width: "16em",
          sortable: false
        }]: [{
          label: "Email address",
          field: "recipient_email",
          filterOptions: {
            enabled: true,
            placeholder: "Search email address",
          },
          width: "16em",
          sortable: false
        }]),
        {
          label: "Subject",
          field: "subject",
          filterOptions: {
            enabled: false,
            placeholder: "Search Subject",
          },
          width: "16em",
          sortable: false
        },
        {
          label: "Status",
          field: "status",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: Object.values(messageStatusDisplay),
          },
          sortable: false
        },
        {
          label: "Failed",
          field: "is_failed",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: ["Yes", "No"]
          },
          sortable: false
        },
        {
          label: "Received at",
          field: "created_at",
          filterOptions: {
            enabled: false
          },
          sortable: false
        },
        {
          label: "Read at",
          field: "email_opened_at",
          filterOptions: {
            enabled: false
          },
          sortable: false
        },
      ];
      }
    }
  }, 
  created() {
    this.loadItems();
  },   
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["recipient.full_name", "status", "is_failed", "recipient_email"]) {
        if (params.columnFilters[col]) {
          if (col == "status") {
            const status = Object.keys(messageStatusDisplay).find(key => messageStatusDisplay[key] === params.columnFilters[col]);
            if (status) {
              columnFilters.push({
                field: "status_id",
                value: status,
              });
            }
          } else if (col == "is_failed") {
            if (params.columnFilters[col] == "No") {
              columnFilters.push({
                field: "status_id",
                value: messageStatus.FAILED,
                operator: 'notEqual',
              });
            } else {
              columnFilters.push({
                field: "status_id",
                value: messageStatus.FAILED,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        // Get topic detail
        const topic = await commsService.getTopicMessageList(
          this.$route.params.id,
          this.$route.params.topicId,
        );
        if (topic && topic.data && topic.data.items.length) {
          this.topicName = topic.data.items[0].subject;
          this.internalTopicName = topic.data.items[0].topic?.name;
          this.topic = topic.data.items[0].topic;
        }

        const response = await commsService.getTopicSentMessageList(
          this.$route.params.id,
          this.$route.params.topicId,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => {
          return {
            ...item,
            subject: item.subject,
            email: item.email,
            status: item.status.name,
            is_failed: item.status_id === messageStatus.FAILED ? 'Yes' : 'No',
            created_at: localeDateStringFromIsoDateTime(item.created_at, true),
            email_opened_at: item.email_opened_at? localeDateStringFromIsoDateTime(item.email_opened_at, true): '-'
          };
        });
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Scheduled Topic not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, perPage, page
    };
  }
};
</script>
  
<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
  